import React from 'react'
import { Layout } from 'components/Layout'
import Search from 'modules/Search/Search'
import { ITranslationData } from 'utils/types'
const {
  defaultLanguage,
  alternateLanguages,
} = require('../../prismic-configuration')

export default function SearchPage() {
  // window is not defined in SSR during gatsby build
  const url = typeof window != 'undefined' ? window.location.pathname : ''

  // Generate the current language from the url
  const language = url.split('/')[2] || defaultLanguage

  const otherTranslations = [defaultLanguage, ...alternateLanguages]
    .filter((lang) => lang !== language)
    .map((lang) => ({
      url: `/search/${lang === defaultLanguage ? '' : lang}`,
      lang,
    })) as ITranslationData['translations']

  return (
    <Layout
      translationData={{
        lang: language,
        url,
        translations: otherTranslations,
      }}
    >
      <Search language={language} />
    </Layout>
  )
}
