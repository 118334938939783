import React, { useEffect, useRef } from 'react'
import { connectSearchBox } from 'react-instantsearch-dom'
import { BsSearch } from 'react-icons/bs'

export default connectSearchBox(({ refine, currentRefinement }) => {
  const searchInputRef = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    // We want to focus on the search input immediately the page is mounted
    searchInputRef?.current?.focus()
  }, [])

  return (
    <div className="flex w-full pt-5 justify-center">
      <div className="w-full max-w-4xl relative">
        <input
          ref={searchInputRef}
          className="h-12 w-full border bg-gray-100 outline-none shadow-sm px-4 rounded-lg text-center border-gray-200"
          placeholder="Search for content..."
          type="text"
          name="search"
          id="search"
          aria-label="Search"
          onChange={(e) => refine(e.target.value)}
          value={currentRefinement}
        />
        <BsSearch className="absolute top-3.5 right-8 text-lg text-gray-600" />
      </div>
    </div>
  )
})
