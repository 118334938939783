import { navigate } from 'gatsby'
import { default as React } from 'react'
import {
  connectStateResults,
  Highlight,
  Hits,
  Index,
  Snippet,
  PoweredBy,
} from 'react-instantsearch-dom'
import { Hit, StateResultsProvided, BasicDoc } from 'react-instantsearch-core'
import { IAlgoliaIndex } from 'utils/types'
import { RiMicroscopeLine } from 'react-icons/ri'

const HitCount = ({ count }: { count: number }) => {
  return (
    <div>
      <p>
        {count || 0} result{count !== 1 ? `s` : ``}
      </p>
    </div>
  )
}

const PageHit = ({ hit }: { hit: Hit }) => {
  return (
    <div>
      <div
        onClick={() => navigate(hit.url)}
        className="px-3 md:px-6 cursor-pointer py-8 border border-transparent hover:border-gray-200 hover:bg-gray-50 transition-all duration-200"
      >
        <h3 className="font-semibold text-base md:text-lg">
          <Highlight attribute="title" hit={hit} tagName="mark" />
        </h3>
        <p className="text-sm md:text-md">
          <Snippet attribute="content" hit={hit} tagName="mark" />
        </p>
      </div>

      <hr className="border-gray-100" />
    </div>
  )
}

const HitsInIndex = connectStateResults(
  ({
    searchResults,
    language,
  }: StateResultsProvided<BasicDoc> & {
    language: string
  }) => {
    const resultsInCurrentLanguage =
      searchResults?.hits.filter(({ lang }) => lang === language) || []

    return (
      <div>
        <div className="flex mb-12 items-center justify-between">
          <HitCount count={resultsInCurrentLanguage.length} />
          <PoweredBy />
        </div>
        {resultsInCurrentLanguage.map((hit) => (
          <PageHit hit={hit} />
        ))}
      </div>
    )
  },
)

const SearchResult = connectStateResults(
  ({
    language,
    allSearchResults,
    indices,
  }: StateResultsProvided<BasicDoc> & {
    indices: IAlgoliaIndex[]
    language: string
  }) => {
    if (!allSearchResults?.query) {
      return (
        <div className="mt-5">
          <div className="flex pt-20 flex-col items-center justify-center">
            <RiMicroscopeLine className="text-[6rem] text-gray-600" />
            <p className="text-center mt-2 text-gray-600">
              Search results will show here when you start typing
            </p>
          </div>
        </div>
      )
    }

    return (
      <div>
        {indices.map((index) => (
          <Index key={index.name} indexName={index.name}>
            <HitsInIndex language={language} />
          </Index>
        ))}

        {!Boolean(allSearchResults?.hits?.length) && (
          <div className="flex justify-center">
            No results found for this query
          </div>
        )}
      </div>
    )
  },
)

export default SearchResult
