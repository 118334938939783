import React, { useMemo, useState } from 'react'
import algoliasearch from 'algoliasearch/lite'
import { InstantSearch } from 'react-instantsearch-dom'
import { IAlgoliaIndex } from 'utils/types'
import SearchBox from './components/SearchBox'
import SearchResult from './components/SearchResults'

interface SearchProps {
  language: string
}

export default function Search({ language }: SearchProps) {
  const [query, setQuery] = useState<string | never>()

  const searchIndices: IAlgoliaIndex[] = [
    { name: `Articles`, title: `Articles` },
  ]

  const searchClient = useMemo(
    () =>
      algoliasearch(
        process.env.GATSBY_ALGOLIA_APP_ID!,
        process.env.GATSBY_ALGOLIA_SEARCH_KEY!,
      ),
    [],
  )

  return (
    <div className="flex mb-32 px-[5%] justify-center">
      <div className="max-w-7xl w-full">
        <InstantSearch
          searchClient={searchClient}
          indexName={searchIndices[0].name}
          onSearchStateChange={({ query }) => setQuery(query)}
        >
          <SearchBox />

          <div className="flex justify-center">
            <div className="mt-2 w-full max-w-4xl">
              <SearchResult language={language} indices={searchIndices} />
            </div>
          </div>
        </InstantSearch>
      </div>
    </div>
  )
}
